import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import compact from 'lodash/compact'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { Badge, Tooltip, CustomInput, Spinner, Alert } from 'reactstrap'
import { useIntercom } from 'react-use-intercom'
import { useSelector } from 'react-redux'

import Addendums from './Addendums'
import Followups from './Followups'
import ConditionBadge from '../common/ConditionBadge'
import RegionOfInterest, { RegionOfInterestParams } from './RegionOfInterest'
import formatGroupLabel from './GroupLabel'
import ConsensusStatement, { CONSENSUS_TEXT } from './ConsensusStatement'
import { Conditions_conditions } from '../../hasura/graphQlQueries/types/Conditions'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'
import { Input, Textarea } from '../../components/common/Input'
import { QueryName } from '../../hasura/queryNames'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { getGpPredictions, gpFilteredPredictions } from '../../lib/aiHelpers'
import { predictions_normalized_insert_input } from '../../../types/globalTypes'
import { allIndexesOf, conditionOptions, filterConditions, stringSimilarity, uniqueCategoriesFor } from '../../lib/helpers'

// @ts-ignore
import speaker from '../../lib/images/speaker.png'
// @ts-ignore
import speakerListening from '../../lib/images/speaker-listening.png'

interface Props {
  addendum?: string
  followupText?: string
  followupDays?: number
  addendumPrivateText?: string
  checked: string[]
  consultation: Consultations_consultations
  customFinding: string
  customFindings: predictions_normalized_insert_input[]
  handleAddCustomFinding: (displayName: string, conditionCategoryId: number) => void
  handleRemoveCustomFinding: (finding: predictions_normalized_insert_input) => void
  handleUpdateChecked: (category: string) => void
  inQueue: boolean
  isLocked: boolean
  lock: (_?: any) => void
  notes: string | undefined
  privateNotes: string | undefined
  selectedCondition: (condition: Conditions_conditions) => void
  selectedConditions: predictions_normalized_insert_input[]
  setAddendum: (addendum: string) => void
  setFollowupText: (followupText: string) => void
  setFollowupDays: (followupDays: number) => void
  setAddendumPrivateText: (addendumPrivateText: string) => void
  setCustomFinding: (customFinding: string) => void
  setNotes: (notes: string) => void
  setPrivateNotes: (privateNotes: string) => void
  unrecognizedSpeech?: string
  displayRegionOfInterest: boolean
  setDisplayRegionOfInterest: (displayRegionOfInterest: boolean) => void
  setRegionOfInterest: (regionOfInterest?: RegionOfInterestParams) => void
  regionOfInterest?: RegionOfInterestParams
}

const BOND_ENTERPRISE_ID = 133

export default (props: Props) => {
  const { trackEvent } = useIntercom()

  const {
    addendum,
    addendumPrivateText,
    checked,
    consultation,
    customFinding,
    customFindings,
    handleAddCustomFinding,
    handleRemoveCustomFinding,
    handleUpdateChecked,
    inQueue,
    isLocked,
    notes,
    privateNotes,
    selectedCondition,
    selectedConditions,
    setAddendum,
    setAddendumPrivateText,
    setCustomFinding,
    setNotes,
    setPrivateNotes,
    followupText,
    setFollowupText,
    followupDays,
    setFollowupDays,
  } = props

  const { conditions, isQuerying }: ConsultationsState = useSelector(consultationsSelector)

  const [openTooltipId, setOpenTooltipId] = useState<string | undefined>(undefined)
  const [displayPrivateNotes, setDisplayPrivateNotes] = useState(false)
  const [displayFollowup, setDisplayFollowup] = useState(false)
  const [justSelected, setJustSelected] = useState<string | undefined>()
  const [autocompleteClosed, setAutocompleteClosed] = useState(false)
  const [isConsensus, setIsConsensus] = useState(false)

  const toggleTooltip = (e: any) => setOpenTooltipId(openTooltipId === e.srcElement.id ? undefined : e.srcElement.id)

  const filteredConditions = filterConditions(conditions, consultation.case.patient.species)
  const options = conditionOptions(filteredConditions, selectedConditions)

  const regions = compact(uniq(filteredConditions.map((c) => c.category?.region))).sort()

  /*
    Effects
  */

  useEffect(() => {
    if (!customFinding) setAutocompleteClosed(false)
  }, [customFinding])

  useEffect(() => {
    setTimeout(() => setJustSelected(undefined), 750)
  }, [justSelected])

  useEffect(() => {
    setDisplayPrivateNotes(consultation.receiving_vet_private_notes !== null)
  }, [consultation])

  useEffect(() => {
    if (!notes?.includes(CONSENSUS_TEXT) && isConsensus) {
      setNotes((notes ? notes : '') + CONSENSUS_TEXT)
    } else {
      setNotes(notes?.replace(CONSENSUS_TEXT, '') || '')
    }
  }, [isConsensus])

  /*
    Methods
  */

  const categories = uniqueCategoriesFor(filteredConditions)

  const noIndexes = allIndexesOf(' no ', notes || '')

  const suggestions = orderBy(
    filteredConditions.filter((c) => {
      const isSelected = selectedConditions.some((s) => s.condition_id === c.id)
      const index = (notes || '').toLowerCase().indexOf(` ${c.display_name.toLowerCase()}`)
      const isNegation = noIndexes.some((idx) => Math.abs(index - idx) < 25)
      const isAiPredication = gpFilteredPredictions(consultation.case, conditions).includes(c.display_name)
      return !isSelected && ((index > -1 && !isNegation) || isAiPredication)
    }),
    ['display_name'],
    ['asc']
  )

  if (props.displayRegionOfInterest) {
    return (
      <RegionOfInterest
        regionOfInterest={props.regionOfInterest}
        setRegionOfInterest={props.setRegionOfInterest}
        setDisplayRegionOfInterest={props.setDisplayRegionOfInterest}
        consultation={consultation}
      />
    )
  }

  const selectCondition = (condition: Conditions_conditions) => {
    selectedCondition(condition)
    setJustSelected(condition.display_name)
  }

  const fuzzyMatch = sortBy(
    filteredConditions.map((condition) => ({
      condition,
      stringSimilarity: Math.max(
        stringSimilarity(customFinding, condition.display_name),
        stringSimilarity(customFinding, condition?.ml_name || '')
      ),
    })),
    (f: any) => -f.stringSimilarity
  )
    .filter((f) => f.stringSimilarity >= 0.15)
    .slice(0, 3)

  const showAutocomplete = fuzzyMatch.length > 0 && !autocompleteClosed

  const isBondConsultation = consultation.case.dicom_server?.organization?.enterprise.id === BOND_ENTERPRISE_ID

  return (
    <form
      onClick={() => {
        if (!isLocked) props.lock()
      }}
      className="w-100 pb-5"
    >
      {inQueue && (
        <div className="d-flex align-items-start">
          <h6 className="text-dark-bg min-width-100px">Conditions</h6>

          <div>
            <div className="d-flex gap-20px">
              <Select
                className="react-select-dark-container"
                classNamePrefix="react-select-dark"
                controlShouldRenderValue={false}
                placeholder="Search..."
                options={options}
                // @ts-ignore
                formatGroupLabel={formatGroupLabel}
                onChange={(option: any) => {
                  const selected = filteredConditions.find((c) => c.id === option.value)
                  if (!selected) return

                  selectCondition(selected)
                }}
              />

              <div className="position-relative">
                <Input
                  dark
                  className="data-hj-allow"
                  style={{ width: '200px' }}
                  autoComplete="off"
                  onChange={(e) => setCustomFinding(e.target.value)}
                  placeholder="Custom..."
                  type="text"
                  value={customFinding || ''}
                />

                {customFinding && (
                  <div style={{ height: 0, bottom: '-10px', width: '250px', zIndex: 99999 }} className="position-absolute left-0">
                    {showAutocomplete ? (
                      <div className="bg--dark p-2 rounded">
                        <p className="text-xxs ls-sm bold m-0">AUTOCOMPLETE</p>

                        <div>
                          {fuzzyMatch.map((f) => (
                            <p
                              onClick={() => {
                                setCustomFinding('')
                                selectCondition(f.condition)
                              }}
                              className="pointer text-s m-0 hover-underline mt-1"
                            >
                              {f.condition.display_name}
                            </p>
                          ))}

                          <p onClick={() => setAutocompleteClosed(true)} className="pointer text-s m-0 hover-underline mt-1">
                            None of these
                          </p>
                        </div>
                      </div>
                    ) : (
                      categories.map((category, idx) => (
                        <Badge
                          className="mx-1"
                          key={idx}
                          color="dark-bg-blue"
                          onClick={() => {
                            setJustSelected(customFinding)
                            // @ts-ignore
                            handleAddCustomFinding(customFinding, category.id)
                          }}
                          role="button"
                        >
                          {category.display_name}
                        </Badge>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>

            {inQueue && (
              <div className="d-flex align-items-start mt-3 position-relative min-height-50px">
                {suggestions.length > 0 && (
                  <p style={{ right: '100%' }} className="text-dark-bg position-absolute text-xs mr-2">
                    Suggestions:
                  </p>
                )}

                <div className="d-flex flex-wrap gap-10px">
                  {suggestions.map((condition, idx) => {
                    const isGpPredication = getGpPredictions(consultation.case)
                      .map((p) => p.display_name || p.condition?.display_name)
                      .includes(condition.display_name)
                    return (
                      <ConditionBadge
                        glow={condition.display_name === justSelected}
                        key={idx}
                        name={!isGpPredication ? condition.display_name : `${condition.display_name} (GP)`}
                        category={condition.category?.display_name}
                        onClick={() => {
                          trackEvent('clicked-suggestion')
                          setJustSelected(condition.display_name)
                          selectedCondition(condition)
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="my-3 d-flex align-items-center" role="group">
        <h6 className="text-dark-bg min-width-100px">Checked</h6>

        {regions.map((region) => {
          const tooltipId = `${region}-tooltip`

          return (
            <label className="d-flex align-items-center" key={region} role="button">
              <CustomInput
                checked={checked.includes(region)}
                className="m-0 data-hj-allow"
                color="secondary"
                disabled={!inQueue}
                id={region}
                onChange={() => handleUpdateChecked(region)}
                role="button"
                type="switch"
                value={region}
              />

              <p className="text-dark-bg text-s m-0">
                <b id={tooltipId} className="text-capitalize pr-3">
                  {region}
                </b>

                <Tooltip fade={false} placement="top" isOpen={openTooltipId === tooltipId} target={tooltipId} toggle={toggleTooltip}>
                  <div className="text-left text-xs">
                    {uniq(filteredConditions.filter((c) => c.category?.region === region).map((c) => c.category?.display_name))
                      .sort()
                      .join(', ')}
                  </div>
                </Tooltip>
              </p>
            </label>
          )
        })}
      </div>
      {isBondConsultation && (
        <Alert color="secondary" className="text-s mw-100 width-fit-content">
          Bond Vet appreciates all possible, even minor, and incidental findings, with listed interpretations and recommendations for
          each
        </Alert>
      )}
      <Textarea
        className="mb-1 min-height-400px max-height-800px data-hj-allow"
        dark
        disabled={!inQueue}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Notes"
        style={{ width: '100%', maxWidth: '900px', height: '50vh' }}
        value={notes || ''}
      />
      <div className="d-flex align-items-start justify-content-between">
        <div className="flex-grow">
          {inQueue && <ConsensusStatement isConsensus={isConsensus} setIsConsensus={setIsConsensus} />}
          <div className="d-flex text-dark-bg text-s flex-row mb-1">
            <div className="underline pointer" onClick={() => setDisplayPrivateNotes(!displayPrivateNotes)}>
              {displayPrivateNotes ? 'Close' : 'Add'} private notes
            </div>

            <div className="underline pointer mx-3" onClick={() => setDisplayFollowup(!displayFollowup)}>
              {displayFollowup ? 'Close' : 'Add'} followup notes
            </div>

            {inQueue && (
              <div className="underline pointer" onClick={() => props.setDisplayRegionOfInterest(true)}>
                {props.regionOfInterest ? 'Edit' : 'Add'} region of interest
              </div>
            )}
          </div>

          {displayPrivateNotes && (
            <Textarea
              className="mt-2 mb-1 data-hj-allow"
              dark
              disabled={!inQueue}
              onChange={(e) => setPrivateNotes(e.target.value)}
              placeholder="Private Notes"
              autoFocus
              style={{ width: '100%', height: `100px`, maxWidth: '900px' }}
              value={privateNotes || ''}
            />
          )}

          {displayFollowup && (
            <Followups
              consultation={consultation}
              followupDays={followupDays}
              setFollowupDays={setFollowupDays}
              followupText={followupText}
              setFollowupText={setFollowupText}
              inQueue={inQueue}
            />
          )}
        </div>

        <div className={`pl-5 flex-center transition-m ${isQuerying[QueryName.UpdateConsultationNotes] ? '' : 'opacity-0'}`}>
          <p className="text-dark-bg text-m mb-0 mr-1">Saving</p>
          <Spinner size="sm" color="primary" />
        </div>
      </div>
      {!inQueue && (
        <Addendums
          consultation={consultation}
          addendum={addendum}
          setAddendum={setAddendum}
          addendumPrivateText={addendumPrivateText}
          setAddendumPrivateText={setAddendumPrivateText}
        />
      )}

      <div style={{ minHeight: '32px' }} className={`d-flex mt-4 gap-10px flex-wrap pe-${inQueue ? 'auto' : 'none'}`}>
        {filteredConditions
          .filter((c) => selectedConditions.map((s) => s.condition_id).includes(c.id))
          .map((condition: Conditions_conditions, idx: number) => (
            <ConditionBadge
              glow={condition.display_name === justSelected}
              key={idx}
              name={condition.display_name}
              category={condition.category?.display_name}
              onClick={() => selectedCondition(condition)}
            />
          ))}

        {customFindings.map((finding, idx) => (
          <ConditionBadge
            glow={finding.display_name === justSelected}
            key={idx}
            name={finding.display_name!}
            category={categories.find((c) => c.id === finding.condition_category_id)?.display_name}
            onClick={() => handleRemoveCustomFinding(finding)}
          />
        ))}
      </div>
    </form>
  )
}

import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import compact from 'lodash/compact'
import isNumber from 'lodash/isNumber'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import extend from 'lodash/extend'
import without from 'lodash/without'
import { Button, CustomInput, Progress, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import CONFIG from '../../config'
import ConditionBadge from '../common/ConditionBadge'
import Pagination from '../consultations/Pagination'
import RadioButton from '../common/RadioButton'
import formatGroupLabel from '../../components/consultations/GroupLabel'
import { Conditions_conditions } from '../../hasura/graphQlQueries/types/Conditions'
import { QueryName } from '../../hasura/queryNames'
import { conditionOptions, emojiFor, ohifStudyUrl, Option, pluralize, searchQueryParams, Species, speciesFor } from '../../lib/helpers'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { deleteVetPredictionsAction, insertPredictionsAction } from '../../hasura/slices/predictions'
import { predictions_normalized_insert_input } from '../../../types/globalTypes'
import { updatePatientSpeciesAction } from '../../hasura/slices/patients'
import { usePrevious } from '../../hooks/usePrevious'
import { usersSelector, UsersState } from '../../hasura/slices/users'
import { isExperiment } from '../../lib/aiHelpers'

import {
  MedicalImagesState,
  fetchMedicalImageAction,
  fetchMedicalImageViewsAction,
  medicalImagesSelector,
  updateMedicalImageFlaggedAction,
  updateMedicalImageViewAction,
  updateMedicalImageSpeciesAction,
} from '../../hasura/slices/medical-images'

interface Props {
  complete: () => void
  currentIdx: number
  queueCount: number
  setIdx: (idx: number) => void
  skippable?: boolean
  suggested?: number[]
}

export default function TaggingComponent(props: Props) {
  const dispatch = useDispatch()

  const { currentIdx, queueCount, suggested } = props

  const { presignedTaggingImageUrl, conditions }: ConsultationsState = useSelector(consultationsSelector)
  const { medicalImage, isQuerying, medicalImageViews }: MedicalImagesState = useSelector(medicalImagesSelector)
  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const [selected, setSelected] = useState<number[]>([])
  const [justSelected, setJustSelected] = useState<string | undefined>()
  const [healthy, setHealthy] = useState(false)

  const medicalImageViewOptions: Option[] = medicalImageViews.map((m) => ({ label: m.display_name, value: m.id }))
  const options = conditionOptions(
    conditions,
    selected.map((condition_id) => ({ condition_id }))
  )
  const previousPredictions = medicalImage?.predictions_normalizeds.filter((p) => p.vet_id && (!isExperiment || p.vet_id === user?.id))
  const previousMedicalImage: any = usePrevious(medicalImage)

  useEffect(() => {
    if (!accessToken || medicalImageViews.length) return

    dispatch(fetchMedicalImageViewsAction(accessToken))
  }, [accessToken])

  useEffect(() => {
    if (previousMedicalImage && medicalImage?.id === previousMedicalImage.id) return

    const healthy = (previousPredictions || []).some((p) => p.condition?.display_name === CONFIG.HEALTHY_CONDITION_NAME)
    setSelected(compact((previousPredictions || []).map((p) => p.condition?.id)))
    setHealthy(healthy)
  }, [medicalImage, previousMedicalImage])

  useEffect(() => {
    if (healthy) setSelected([])
  }, [healthy])

  useEffect(() => {
    setTimeout(() => setJustSelected(undefined), 750)
  }, [justSelected])

  const insertPrediction = async () => {
    const type = `specialist-medical_image${searchQueryParams('t') === 'experiment' ? '-experiment_one' : ''}`
    const params = { vet_id: user?.id, medical_images_id: medicalImage?.id, type }
    const predictions: predictions_normalized_insert_input[] = healthy
      ? [extend({}, params, { condition_id: conditions.find((c) => c.display_name === CONFIG.HEALTHY_CONDITION_NAME)?.id })]
      : selected.map((condition_id) => extend({}, params, { condition_id }))

    const nothingChanged = isEqual(
      predictions.map((p) => p.condition_id).sort(),
      previousPredictions?.map((p) => p.condition?.id).sort()
    )
    if (nothingChanged) return

    if (previousPredictions?.length && medicalImage?.id) {
      await dispatch(deleteVetPredictionsAction(accessToken, medicalImage?.id))
    }

    const skipped = !healthy && !selected.length
    if (skipped) return

    return dispatch(insertPredictionsAction(accessToken, predictions))
  }

  const handleClickedNext = async () => {
    await insertPrediction()

    const isDone = currentIdx + 1 === queueCount
    if (isDone) {
      props.complete()
    } else {
      props.setIdx(currentIdx + 1)
    }
  }

  const handleClickedPrevious = async () => {
    await insertPrediction()
    props.setIdx(currentIdx - 1)
  }

  const handleSkip = async (idx: number) => {
    if (currentIdx === idx) return

    await insertPrediction()
    props.setIdx(idx)
  }

  const updateSpecies = async (species: Species) => {
    if (medicalImage?.case?.patient) {
      await dispatch(updatePatientSpeciesAction(accessToken, medicalImage.case.patient.id, species))
    } else {
      await dispatch(updateMedicalImageSpeciesAction(accessToken, medicalImage!.id, species))
    }
    dispatch(fetchMedicalImageAction(accessToken, medicalImage!.id))
  }

  const totalCount = Math.max(3, Math.ceil(100 * (currentIdx / queueCount)))
  const species = medicalImage?.species || medicalImage?.case?.patient?.species

  // @ts-ignore
  const suggestions: Conditions_conditions[] = (suggested || [])
    ?.map((s) => conditions.find((c) => c.id === s))
    .filter((c) => !isUndefined(c) && !selected.includes(c.id))

  if (isQuerying[QueryName.MedicalImages]) {
    return <Spinner className="center" size="lg" color="primary" />
  }

  const updateMedicalImageFlagged = () =>
    dispatch(updateMedicalImageFlaggedAction(accessToken!, medicalImage?.id!, !medicalImage?.flagged))

  const updateMedicalImageView = (view_id: number) => dispatch(updateMedicalImageViewAction(accessToken!, medicalImage?.id!, view_id))

  const selectedCondition = (condition: Conditions_conditions) => {
    if (selected.includes(condition.id)) {
      setSelected(without(selected, condition.id))
      return
    }

    setJustSelected(condition.display_name)
    setSelected(selected.concat(condition.id))
  }

  return (
    <div
      className="d-flex align-items-start justify-content-end w-100 px-5 overflow-hidden"
      style={{ height: `calc(100vh - ${CONFIG.HEADER_HEIGHT}px)` }}
    >
      <div className="h-100 pb-5 d-flex flex-column align-items-end" style={{ maxWidth: 'calc(100% - 375px)' }}>
        {medicalImage?.case?.dicom_server_study_instance_uid && (
          <a
            className="text-link text-s mb-2 m-0"
            target="_blank"
            href={ohifStudyUrl(medicalImage?.case.dicom_server_study_instance_uid, medicalImage.case.dicom_source)}
          >
            Open Viewer
          </a>
        )}

        <img className="mw-100 mh-100" src={presignedTaggingImageUrl} />
      </div>

      <div className="d-flex flex-column pl-4 align-items-start" style={{ width: '375px' }}>
        <div className="d-flex align-items-start w-100">
          {props.skippable && (
            <Button size="sm" className="mr-4" color="primary" onClick={props.complete}>
              Skip
            </Button>
          )}

          <div className="mr-4 flex-grow">
            {queueCount > 0 && (
              <Progress
                role="button"
                onClick={(e) => {
                  handleSkip(Math.floor((e.nativeEvent.offsetX * queueCount) / e.currentTarget.clientWidth))
                }}
                style={{ width: '100%' }}
                value={totalCount}
              />
            )}

            <p className="text-dark-bg text-s mb-0 mt-1 text-right">
              {emojiFor(speciesFor(species))} {medicalImage?.case?.patient?.display_name}
            </p>
          </div>

          {queueCount > 0 && (
            <Pagination
              currentIdx={currentIdx}
              handleClickedNext={handleClickedNext}
              handleClickedPrevious={handleClickedPrevious}
              nextEnabled={true}
              totalCount={queueCount}
            />
          )}
        </div>

        <div className="d-flex align-items-end w-100 my-3">
          <CustomInput
            checked={medicalImage?.flagged || false}
            className="m-0 custom-switch-light"
            id="flagged"
            onChange={updateMedicalImageFlagged}
            role="button"
            type="switch"
            color="danger"
          />

          <p className="text-dark-bg text-m b-0 m-0">{medicalImage?.flagged ? 'Image is flagged' : 'Flag image'}</p>
        </div>

        {!medicalImage?.flagged && (
          <div className="w-100">
            <div className="my-4">
              <h6 className="text-dark-bg mb-2">
                <span className="mr-2">1.</span>Species
              </h6>

              <div className="d-flex ">
                <RadioButton
                  dark
                  onClick={() => updateSpecies(Species.Dog)}
                  checked={species === Species.Dog}
                  label={'Dog ' + emojiFor(Species.Dog)}
                />

                <RadioButton
                  dark
                  onClick={() => updateSpecies(Species.Cat)}
                  checked={species === Species.Cat}
                  label={'Cat ' + emojiFor(Species.Cat)}
                />
              </div>
            </div>

            <div className="my-5">
              <h6 className="text-dark-bg mb-2">
                <span className="mr-2">2.</span>View
              </h6>

              <Select
                placeholder="View..."
                className="react-select-dark-container"
                classNamePrefix="react-select-dark"
                options={medicalImageViewOptions}
                value={medicalImageViewOptions.find((m) => m.value === medicalImage?.view?.id) || null}
                onChange={(option) => {
                  if (!isNumber(option?.value)) return

                  updateMedicalImageView(option!.value)
                }}
              />
            </div>

            <div>
              <h6 className="text-dark-bg mb-2">
                <span className="mr-2">3.</span>Conditions
              </h6>

              <div className="d-flex align-items-end">
                <CustomInput
                  id="healthy"
                  checked={healthy}
                  className="m-0 custom-switch-light"
                  role="button"
                  type="switch"
                  onChange={() => setHealthy(!healthy)}
                />

                <p className="text-dark-bg text-m mr-2 mb-0">
                  Image is <b>{!healthy && 'un'}healthy</b>
                  <span className="ml-2">
                    {healthy ? '' : selected.length ? `(${pluralize('condition', selected.length)})` : '(no conditions)'}
                  </span>
                </p>
              </div>

              {!healthy && (
                <div className="mt-2">
                  <Select
                    autoFocus={true}
                    placeholder="Conditions..."
                    className="react-select-dark-container mb-3"
                    classNamePrefix="react-select-dark"
                    controlShouldRenderValue={false}
                    options={options}
                    onChange={(option) => {
                      // @ts-ignore
                      const condition = conditions.find((c) => c.id === option?.value)
                      if (condition) selectedCondition(condition)
                    }}
                    // @ts-ignore
                    formatGroupLabel={formatGroupLabel}
                  />

                  {conditions
                    .filter((c) => selected.includes(c.id))
                    .map((condition, idx) => (
                      <ConditionBadge
                        glow={condition.display_name === justSelected}
                        key={idx}
                        name={condition.display_name}
                        category={condition.category?.display_name}
                        onClick={() => selectedCondition(condition)}
                      />
                    ))}

                  {suggestions.length > 0 && !isExperiment() && (
                    <div className="mt-5">
                      <h6 className="text-dark-bg mb-2">Suggestions</h6>

                      {suggestions.map((condition, idx) => (
                        <ConditionBadge
                          glow={condition.display_name === justSelected}
                          key={idx}
                          name={condition.display_name}
                          category={condition.category?.display_name}
                          onClick={() => selectedCondition(condition)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

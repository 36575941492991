import React, { useState } from 'react'
import moment from 'moment'
import without from 'lodash/without'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import MedicalImageThumbnail from '../common/MedicalImageThumbnail'
import { downloadStudyParamsFor } from '../../lib/combineCompareHelpers'
import { ohifStudyUrl, inCase, pluralize } from '../../lib/helpers'

import {
  Consultations_consultations,
  Consultations_consultations_case_patient_cases,
} from '../../hasura/graphQlQueries/types/Consultations'

import {
  ConsultationsState,
  consultationsSelector,
  downloadStudyAction,
  fetchConsultationPdfAction,
} from '../../hasura/slices/consultations'

import { usersSelector, UsersState } from '../../hasura/slices/users'

// @ts-ignore
import rightArrowWhite from '../../lib/images/right-arrow-white.svg'
// @ts-ignore
import dots from '../../lib/images/dots.svg'
// @ts-ignore
import ballotBox from '../../lib/images/ballot-box.svg'
// @ts-ignore
import ballotBoxChecked from '../../lib/images/ballot-box-checked.svg'

interface Props {
  consultation: Consultations_consultations
  otherCases: Consultations_consultations_case_patient_cases[]
  isQuerying: boolean
}

export default function OtherCases(props: Props) {
  const dispatch = useDispatch()

  const { otherCases, consultation, isQuerying } = props

  const [displayOtherCases, setDisplayOtherCases] = useState(true)
  const [selectedOtherCaseIds, setSelectedOtherCaseIds] = useState<number[]>([])

  const { accessToken }: UsersState = useSelector(usersSelector)
  const { presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)

  const { patient } = consultation.case

  /*
    Methods
  */

  // when reader has selected combine/compare cases that aren't selected by the rDVM, the call to downloadStudyAction incorporates those selected by the reader
  const downloadMultipleStudies = (selectedCaseIds: number[]) => {
    if (!selectedCaseIds.length) return

    const study = downloadStudyParamsFor([consultation])[0]
    study.isCombineCompare = true
    study.combineCompareCaseIds = study.combineCompareCaseIds?.length
      ? [...study.combineCompareCaseIds, ...selectedCaseIds]
      : selectedCaseIds
    dispatch(downloadStudyAction(accessToken, [study]))
  }

  const selectOtherCase = (caseId: number) =>
    setSelectedOtherCaseIds(
      selectedOtherCaseIds.includes(caseId) ? without(selectedOtherCaseIds, caseId) : [...selectedOtherCaseIds, caseId]
    )

  return (
    <div className="mt-2 mb-5 pt-2 position-relative border-top">
      <div onClick={() => setDisplayOtherCases(!displayOtherCases)} className="d-flex align-items-center pointer my-2">
        <h6 className="text-dark-bg mb-0">Other cases ({otherCases.length})</h6>
        <img
          style={{ transform: displayOtherCases ? 'rotate(90deg)' : '' }}
          className="transition-f ml-2 icon-xxxs"
          src={rightArrowWhite}
        />
      </div>

      {displayOtherCases && (
        <div>
          {otherCases.map((c, idx) => (
            <div className="d-flex align-items-center my-3" key={idx}>
              <img
                src={selectedOtherCaseIds.includes(c.id) ? ballotBoxChecked : ballotBox}
                className="mr-3 icon-xxxs pointer"
                onClick={() => selectOtherCase(c.id)}
              />

              <div className="width-300px">
                <div className="d-flex gap-10px flex-wrap mb-1">
                  {inCase(presignedCaseImageUrls, c)
                    .slice(0, 3)
                    .map((src: string, idx: number) => (
                      <MedicalImageThumbnail irxMode src={src} key={idx} />
                    ))}
                </div>

                <p className="text-dark-bg m-0 text-s">
                  {pluralize('image', inCase(presignedCaseImageUrls, c).length)}
                  {c.consultations.length ? ' and report' : ''} from {moment(c.created_at).format('M/DD/YY')}
                </p>
              </div>

              <UncontrolledDropdown className="ml-4 dropdown-menu-dots" onClick={(e: any) => e.stopPropagation()}>
                <DropdownToggle caret>
                  <img className="icon-s mb-1" src={dots} />
                </DropdownToggle>

                <DropdownMenu className="bg--input">
                  {c.dicom_server_study_id && (
                    <DropdownItem text>
                      <p
                        className="text-dark-bg pointer m-0 text-s nowrap"
                        onClick={() =>
                          dispatch(
                            downloadStudyAction(accessToken, [
                              {
                                studyId: c.dicom_server_study_id!,
                                isCombineCompare: false,
                                sourceIsDicomCluster: c.dicom_source === 'dicom_cluster',
                                patientName: patient.display_name,
                              },
                            ])
                          )
                        }
                      >
                        Download images
                      </p>
                    </DropdownItem>
                  )}

                  <DropdownItem text>
                    <p
                      className="text-dark-bg pointer m-0 text-s"
                      onClick={() => window.open(ohifStudyUrl(c.dicom_server_study_instance_uid, c.dicom_source))}
                    >
                      Open viewer
                    </p>
                  </DropdownItem>

                  {c.consultations.length > 0 && (
                    <DropdownItem text>
                      <p
                        onClick={() => dispatch(fetchConsultationPdfAction(c.consultations[0].s3_url!, patient, undefined, true))}
                        className="text-dark-bg pointer m-0 text-s"
                      >
                        View report
                      </p>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ))}

          <Button
            className="irx-button flex-center"
            color="primary"
            disabled={!selectedOtherCaseIds.length || isQuerying}
            onClick={() => downloadMultipleStudies(selectedOtherCaseIds)}
            outline
            size="sm"
          >
            Download selected{isQuerying && <Spinner className="ml-1" color="primary" size="sm" />}
          </Button>
        </div>
      )}
    </div>
  )
}
